export default {
	adds:{
		one:"帳戶設定",
		two:"收款設定",
		three:"身份設定",
		four:"用戶回饋",
		five:"登出",
		verifySuccess: "認證通過",
		verifyFailed: "未認證",
		black: '計畫書',
	},
	types: [
		{ v: 5, name: '理财账户' },
		{ v: 4, name: '极速合约账户' },
		{ v: 3, name: '法币账户' },
		{ v: 2, name: '合约账户' },
		{ v: 1, name: '币币账户' },
	],
	billTypes: [
		{v: 101, name: '充值'},
		{v: 102, name: '存入'},
		{v: 103, name: '冻结'},
		{v: 104, name: '解冻'},
		{v: 105, name: '存入'},
		{v: 106, name: '存入'},
		{v: 201, name: '提现冻结'},
		{v: 202, name: '提现'},
		{v: 203, name: '提现成功'},
		{v: 204, name: '提现失败'},
		{v: 205, name: '提现手续费'},
		{v: 206, name: '转出'},
		{v: 207, name: '转入'},
		{v: 208, name: '币币转出'},
		{v: 209, name: '币币转入'},
		{v: 301, name: '币币手续费'},
		{v: 302, name: '合约收益'},
		{v: 303, name: '合约亏损'},
		{v: 311, name: '购买期权'},
		{v: 312, name: '期权收益'},
		{v: 313, name: '期权退回'},
		{v: 314, name: '期权手续费'},
		{v: 315, name: '币币买入冻结'},
		{v: 316, name: '币币买入扣除'},
		{v: 317, name: '币币买入返回'},
		{v: 318, name: '币币买入到账'},
		{v: 319, name: '币币买入到账'},
		{v: 320, name: '币币卖出冻结'},
		{v: 321, name: '币币卖出扣除'},
		{v: 322, name: '币币卖出返回'},
		{v: 323, name: '币币卖出到账'},
		{v: 324, name: '币币卖出到账'},
		{v: 325, name: '币币手续费'},
		{v: 401, name: '矿机加入'},
		{v: 402, name: '矿机返回'},
		{v: 403, name: '矿机收入'},
		{v: 404, name: '矿机退出'},
		{v: 405, name: '矿机退出手续费'},
		{v: 501, name: '盲盒购买'},
		{v: 502, name: '盲盒收入'},
	],
	common: {
		confirm: "確認",
		cancel: "取消",
		submit: "提交",
		reset: "重置",
		next: "下一步",
		review: "稽核中",
		copy_success: "複製成功！",
		copy_error: "複製失敗！",
		more: "更多",
		success: "成功",
		fail: "失敗",
		place_input: "請輸入內容",
		form_empty: "暫無記錄",
		download: "下載",
		history_record: "歷史記錄",
		closing_position: "平倉",
		tip: "提示",
		forget: "忘記密碼",
		all: "全部",
	},
	message: {
		cancel_order: "是否撤銷委託?",
		stop_out: "是否強制平倉?",
	},
	header: {
		nav1: "法幣交易",
		nav2: "幣幣交易",
		nav3: "合约交易",
		nav4: "极速合约",
		nav5: "存幣寶",
		nav6: "IEO認購",
		nav7: "我的資產",
		setting_account: "帳戶設定",
		setting_collection: "收款設定",
		setting_authentication: "身份設定",
		setting_feedback: "用戶回饋",
		logout: "登出",
	},
	footer: {
		tit1: "關於我們",
		tit2: "用戶支持",
		tit3: "幫助中心",
		tit4: "聯繫我們",
		tit5: "線上客服",
		p1: "平臺簡介",
		p2: "項目公示",
		p3: "隱私政策",
		p4: "如何郵箱註冊",
		p5: "如何實名認證",
		p6: "如何設定交易密碼",
		p7: "什麼是充幣,如何充幣",
		p8: "什麼是提幣,如何提幣",
		p9: "幣幣交易新手指南",
	},
	assets: {
		asset_verview: "資產總覽",
		asset_verview_conversion: "資產總覽折合",
		account_assets: "帳戶資產",
		financial_records: "財務記錄",
		tab_trade: "幣幣帳戶",
		tab_legal: "法幣帳戶",
		tab_lever: "合約帳戶",
		tab_second: "極速合約帳戶",
		tab_financial: "理財帳戶",
		tab_transfer: "劃轉",
		recharge: "充幣",
		withdraw: "提幣",
		currency_transfer: "幣種劃轉",
		asset_transfer: "資產劃轉",
		transfer_record: "劃轉記錄",
	},
	deposit: {
		tab_deposit: "DeFi",
		tab_record: "DeFi記錄",
		reference_annualization: "參攷年化",
		state: "狀態",
		term: "期限",
		day: "天",
		buy: "購買",
		min_amount: "最小金額",
		daily: "日產量",
		waiting_time: "等待時間",
		time: "時間",
		everyday: "每天",
		escrow_funds: "託管資金",
		returnType: "到期返回",
		early_withdrawal: "提前提款",
		estimated_income: "預計收入",
		available_assets: "可用資產"
	},
	home: {
		download: "West Wallet下載",
		ios_download: "IOS下載",
		android_download: "安卓下載",
		banner_p1: "快速開動您的投資組合-->",
		banner_p2: "快速開動您的",
		banner_p3: "加密投資組合",
		banner_p4: "West Wallet是買賣加密貨幣最簡單的地方。 立即注册並開始使用",
		view_more: "查看更多",
		p1: "全球領先的數位資產交易平臺",
		p2: "為全球超過130個國家的數百萬用戶提供安全、可信賴的數位資產交易及資產管理服務",
		p3: "安全可信賴",
		p4: " 年數位資產金融服務經驗",
		p5: "專業分佈式架構和防DDOS攻擊系統",
		p6: "全球生態佈局",
		p7: "50 多個國家設立本土化交易服務中心",
		p8: "打造多業務形態為一體的區塊鏈生態圈",
		p9: "用戶至上",
		p10: "建立先行賠付機制",
		p11: "設立投資者保護基金",
		p12: "立即開啟您的數位貨幣之旅",
		p13: "為您提供最便捷的數位貨幣交易方式",
		p14: "隨時隨地多平臺終端交易",
		p15: "覆蓋iOS、Android、Windows多個平臺,支持全業務功能",
	},
	ieo: {
		tab_iep: "DeFi",
		tab_record: "DeFi記錄",
		label_issue_price: "發行價格",
		label_number: "認購數量",
		label_money: "合計支付",
		label_time: "認購時間",
	},
	legal: {
		banner_p1: "C2C:快捷買賣數位貨幣",
		banner_p2: "C2C是一個點對點交易平臺,用戶可以在C2C直接與商家",
		banner_p3: "用想要的價格和付款方式買賣比特幣和其他數位貨幣。",
		table_legal:"交易大廳",
		table_order:"買賣幣訂單",
		buy: "購買",
		sell: "出售",
		steps_title: "三個步驟完成P2P交易,零手續費買幣！",
		steps_tit1: "選擇您的商戶",
		steps_tit2: "向賣家收款/付款",
		steps_tit3: "發放/領取加密貨幣",
		steps_p1: "選擇符合您喜好的價格以及支付方式的廣告。",
		steps_p2: "輸入購買/出售數量和成交金額以完成訂單。",
		steps_p3: "通過建議的支付方式向賣家收款/付款。",
		steps_p4: "完成法幣交易後,在West Wallet P2P上點擊",
		steps_p5: "[已轉帳,通知賣家]。 West Wallet將不收取任何費用。",
		steps_p6: "賣家收到款項後將發放貨幣。 前往法幣帳號查看",
		steps_p7: "您收到的貨幣。 確認收款後,在West Wallet P2P",
		steps_p8: "上向買家發放加密貨幣。",
	},
	lever: {
		market_trand: "市價交易",
		limit_trand: "限價交易",
		tip: "1手等於1BTC",
		handicap: "盤口",
		whole_transaction: "全站成交",
		tab1: "當前持倉",
		tab2: "全部持倉",
		tab3: "全部掛單",
		tab4: "全部平倉",
		tab5: "全部撤單",
		tab6: "一鍵平倉",
		close_position: "確定平倉",
		position1: "全部平倉",
		position2: "只憑多單",
		position3: "只憑空單",
		positionState1: "待開倉",
		positionState2: "待平倉",
		positionState3: "已完成",
	},
	login: {
		tip1: "歡迎登錄West Wallet",
		tip2: "隨時隨地交易",
		tip3: "歡迎註冊West Wallet",
		tip4: "歡迎來到West Wallet",
	},
	second: {
		transfer_model: "交易模式",
		open_number: "開倉數量",
		open_time: "開倉時間",
		second_account: "秒合約帳戶",
		profit_rate: "盈利率",
		buy_long: "買漲",
		buy_short: "買跌",
		transfering: "交易中",
		closed_position: "已平倉",
	},
	trade: {
		transfer_amount: "交易額",
		buy: "買入",
		sell: "賣出",
		now_entrust: "當前委託",
		all_entrust: "全部委託",
		history_entrust: "歷史委託",
	},
	personal: {
			tit: "基本資訊",
			tit1: "個人",
			tit2: "安全等級",
			state1: "強",
			nav1: "郵箱驗證",
			nav2: "我的邀請碼",
			nav3: "登入密碼",
			nav4: "交易密碼",
			nav5: "收款設定",
			nav6: "身份認證",
			nav7: "用戶回饋",
			modify: "修改",
			goSetting: "去設定",
			login_psd: "修改登入密碼",
			trans_pad: "修改交易密碼",
			collection: "收款設定",
			authentication: "身份認證",
			feedback: "用戶回饋"
	},
	form: {
		label_oldPsd: "舊密碼",
		place_oldPsd: "請輸入舊密碼",
		label_newPsd: "新密碼",
		place_newPsd: "請輸入新密碼",
		label_crmPsd: "確認密碼",
		place_crmPsd: "請再次輸入新密碼",
		label_bankType: "支付方式",
		place_bankType: "請選擇收款管道",
		label_bank: "銀行卡",
		label_realName:"真實姓名",
		palce_realName:"請輸入真實姓名",
		label_bankName:"開戶行",
		palce_bankName:"請輸入開戶行",
		label_bankBranch:"開戶支行",
		palce_bankBranch:"請輸入開戶支行",
		label_bankNumber:"銀行卡號",
		palce_bankNumber:"請輸入銀行卡號",
		label_cardNumber: "證件號",
		place_cardNumber: "請輸入證件號碼",
		card_img1: "點擊上傳證件首頁",
		card_img2: "點擊上傳證件資訊頁",
		card_img_tip:"您提交的資訊會進行加密隔離存儲,提交的資訊由系統自動稽核,請放心填寫。",
		label_feedbackType: "問題類型",
		place_feedbackType: "請選擇問題類型",
		feedbackType0: "提款相關",
		feedbackType1: "區塊鏈相關",
		label_feedbackTitle: "標題",
		place_feedbackTitle: "請輸入標題",
		label_feedbackCont: "描述",
		place_feedbackCont: "請輸入描述",
		place_currency: "請選擇幣種",
		place_account: "請選擇帳戶",
		label_acrrency: "幣種",
		label_number: "數量",
		label_record: "記錄",
		label_time: "時間",
		label_available: "可用",
		label_freeze: "凍結",
		label_total: "折合",
		label_operate: "操作",
		label_transfer_currency: "劃轉幣種",
		label_transfer_account: "劃轉帳戶",
		label_account_balance: "帳戶餘額",
		label_transfer_number: "劃轉數量",
		place_transfer_number: "請輸入劃轉數量",
		label_buy_price: "買入價",
		label_buy_number: "買入量",
		place_buy_market: "以市場最優價格買入",
		label_sell_price: "賣出價",
		label_sell_number: "賣出量",
		place_sell_market: "以市場最優價格賣出",
		product_name: "產品名稱",
		buy_total: "購買總數量",
		earnings: "累計收益",
		product_cycle: "產品週期",
		state: "狀態",
		time: "時間",
		place_phone_email: "手機郵箱",
		login: "登入",
		register: "注册",
		label_price: "價格",
		place_price: "請輸入價格",
		label_multiple: "倍數",
		place_multiple: "請選擇倍數",
		label_hand: "手數",
		place_hand: "請輸入手數",
		label_volume: "24H量",
		daily_fluctuations: "日漲跌",
		label_quotes: "近三天",
		label_toTrade: "去交易",
		available_amount: "可用額",
		label_transfer_psd: "交易密碼",
		palce_transfer_psd: "請輸入交易密碼",
		buy_long: "買多",
		sell_short: "賣空",
		elail_login: "郵箱登錄",
		phone_login: "手機登入",
		label_email: "郵箱",
		place_email: "請輸入郵箱",
		label_phone: "手機號",
		place_phone: "請輸入手機號",
		label_password: "密碼",
		place_password: "請輸入密碼",
		remember_psd: "記住密碼",
		no_account: "還沒有帳號？",
		forget_psd: "忘記密碼？",
		label_verify: "驗證碼",
		place_Verify: "請輸入驗證碼",
		send: "發送",
		label_invite: "邀請碼",
		place_invite: "請輸入邀請碼",
		have_account: "有帳號？",
		go_login: "去登入",
		label_linkName: "鏈名稱",
		label_recharge_address: "提幣地址",
		place_recharge_address: "請輸入提幣地址",
		label_recharge_number: "充幣數量",
		place_recharge_number: "請輸入充幣數量",
		label_wallet_address: "錢包地址",
		place_wallet_address: "請輸入錢包地址",
		label_withdraw_number: "提幣數量",
		place_withdraw_number: "請輸入提幣數量",
		place_min_withdraw: "最小提幣數量",
		label_remark: "備註",
		place_remark: "請輸入備註",
		label_transPsd: "交易密碼",
		place_transPsd: "請輸入交易密碼",
		message1: "請輸入金額",
		message2: "帳戶餘額不足",
		label_amount: "金額",
		place_amount: "請輸入金額",
	},
	table: {
		label_time: "時間",
		label_title: "交易對",
		label_direction: "方向",
		label_price: "價格",
		label_number: "數量",
		label_volume: "成交量",
		label_operate: "操作",
		label_acrrency: "幣種",
		label_last_price: "最新價",
		label_ncrease: "漲幅",
		label_height_price: "最高價",
		label_low_price: "最低价",
		label_quantity: "24H量",
		label_buyPrice: "購買價",
		label_finalPrice: "成交價",
		label_expectedProfit: "預計盈虧",
		label_countDown: "倒數計時",
		sell: "賣",
		buy: "買",
		label_type: "類型",
		label_open_price: "開倉價",
		label_now_price: "當前價",
		label_bail: "保證金",
		label_stop_profit: "止盈價",
		label_stop_loss: "止損價",
		label_open_time: "開倉時間",
		label_fee: "手續費",
		label_overnight_fee: "隔夜費",
		label_profit_loss: "盈虧",
		label_total_amount: "總金額",
		label_order_time: "訂單時長",
		label_order_income: "訂單收入",
		label_state: "狀態",
		label_endTime: "結束時間",
		state0: "待稽核",
		state1: "已完成",
	},
	params: {
		recordType1: "支出",
		recordType2: "充值",
		ieoState0: "全部",
		ieoState1: "預售中",
		ieoState2: "認購中",
		ieoState3: "已結束",
		depositState0: "火熱進行中",
		depositState1: "敬請期待",
		lockStateType0: "運行中",
		lockStateType1: "已結束",
		lockStateType2: "已取消",
	},
    city: {
		albania: "阿爾巴尼亞",
		algeria: "阿爾及利亞",
		argentina: "阿根廷",
		armenia: "亞美尼亞",
		australia: "澳大利亞",
		pakistan: "巴基斯坦",
		austria: "奧地利",
		bahrain: "巴林",
		belgium: "比利時",
		bosnia_and_Herzegovina: "波士尼亞和黑塞哥維那",
		brazil: "巴西",
		brunei: "汶萊",
		bulgaria: "保加利亞",
		cambodia: "柬埔寨",
		canada: "加拿大",
		cameroon: "喀麥隆",
		chile: "智利",
		colombia: "哥倫比亞",
		costa_Rica: "哥斯达黎加",
		croatia: "克羅地亞",
		cyprus: "塞浦路斯",
		czech_Republic: "捷克共和國",
		denmark: "丹麥",
		dominican_Republic: "多米尼加共和国",
		egypt: "埃及",
		estonia: "愛沙尼亞",
		ethiopia: "埃塞俄比亚",
		finland: "芬蘭",
		france: "法國",
		georgia: "格魯吉亞",
		germany: "德國",
		ghana: "加納",
		greece: "希臘",
		guyana: "蓋亞那",
		honduras: "洪都拉斯",
		hong_Kong_China: "中國香港",
		hungary: "匈牙利",
		iceland: "冰島",
		ireland: "愛爾蘭",
		italy: "義大利",
		india: "印度",
		indonesia: "印度尼西亞",
		israel: "以色列",
		iran: "伊朗",
		iraq: "伊拉克",
		japan: "日本",
		kazakstan: "哈薩克共和國",
		kenya: "肯尼亚",
		korea: "韓國",
		kuwait: "科威特",
		kyrgyzstan: "吉爾吉斯斯坦",
		laos: "老撾",
		latvia: "拉脫維亞",
		lithuania: "立陶宛",
		luxembourg: "盧森堡",
		macao_China: "中國澳門",
		macedonia: "馬其頓",
		malaysia: "馬來西亞",
		malta: "馬爾他",
		mexico: "墨西哥",
		moldova: "摩爾達瓦",
		monaco: "摩納哥",
		mongolia: "蒙古",
		montenegro: "黑山共和國",
		morocco: "摩洛哥",
		myanmar: "緬甸",
		netherlands: "荷蘭",
		new_Zealand: "新西蘭",
		nepal: "尼泊爾",
		nigeria: "尼日利亞",
		norway: "挪威",
		oman: "阿曼",
		palestine: "巴勒斯坦",
		panama: "巴拿馬",
		paraguay: "巴拉圭",
		peru: "秘魯",
		philippines: "菲律賓",
		poland: "波蘭",
		portugal: "葡萄牙",
		puerto_Rico: "波多黎各",
		qatar: "卡塔爾",
		romania: "羅馬尼亞",
		russia: "俄羅斯",
		republic_of_Trinidad_and_Tobago: "特立尼达和多巴哥共和国",
		rwanda: "卢旺达",
		saudi_Arabia: "沙烏地阿拉伯",
		serbia: "塞爾維亞",
		singapore: "新加坡",
		slovakia: "斯洛伐克",
		slovenia: "斯洛文尼亞",
		south_Africa: "南非",
		spain: "西班牙",
		sri_Lanka: "斯里蘭卡",
		sweden: "瑞典",
		switzerland: "瑞士",
		taiwan_China: "中國臺灣",
		tajikistan: "塔吉克共和國",
		tanzania: "坦尚尼亞",
		thailand: "泰國",
		turkey: "土耳其",
		turkmenistan: "土庫曼共和國",
		ukraine: "烏克蘭",
		united_Arab_Emirates: "阿拉伯聯合大公國",
		united_Kingdom: "英國",
		united_States: "美國",
		uzbekistan: "烏茲別克",
		venezuela: "委內瑞拉",
		vietnam: "越南",
		afghanistan: "阿富汗",
		angola: "安哥拉",
		azerbaijan: "阿塞拜疆",
		bangladesh: "孟加拉",
		belarus: "白俄羅斯",
		belize: "貝裡斯",
		benin: "貝南",
		bhutan: "不丹",
		bolivia: "玻利維亞",
		botswana: "博茨瓦纳",
		british_Virgin_Islands: "英屬維京群島",
		burkina_Faso: "伯基納法索",
		burundi: "布隆迪",
		cape_Verde: "佛得角",
		cayman_Islands: "開曼群島",
		central_African_Republic: "中非共和國",
		chad: "乍得",
		comoros: "科摩羅",
		the_Republic_of_the_Congo: "剛果（布）",
		democratic_Republic_of_the_Congo: "剛果（金）",
		djibouti: "吉佈提",
		ecuador: "厄瓜多尔",
		el_Salvador: "薩爾瓦多",
		equatorial_Guinea: "赤道幾內亞",
		eritrea: "厄立特里亚",
		fiji: "斐濟",
		gabon: "加蓬",
		gambia: "岡比亞",
		greenland: "格陵蘭",
		guatemala: "危地马拉",
		guinea: "幾內亞",
		haiti: "海地",
		isle_of_Man: "馬恩岛",
		cote_d_Ivoire: "科特迪瓦",
		jamaica: "牙買加",
		jordan: "約旦",
		lebanon: "黎巴嫩",
		lesotho: "萊索托",
		liberia: "利比里亚",
		libya: "利比亞",
		madagascar: "馬达加斯加",
		malawi: "馬拉维",
		maldives: "馬尔代夫",
		mali: "馬里",
		mauritania: "毛里塔尼亞",
		mauritius: "毛里求斯",
		mozambique: "莫桑比克",
		namibia: "納米比亞",
		nicaragua: "尼加拉瓜",
		republic_of_Niger: "尼日尔",
		north_Korea: "朝鮮",
		reunion: "留尼汪",
		san_Marino: "聖馬力諾",
		senegal: "塞內加爾",
		sierra_Leone: "塞拉利昂",
		somalia: "索馬里",
		sudan: "蘇丹",
		suriname: "蘇里南",
		eswatini: "斯威士兰",
		syria: "敘利亞",
		togo: "多哥",
		tonga: "汤加",
		tunisia: "突尼斯",
		united_States_Virgin_Islands: "美屬維爾京群島",
		uganda: "烏干達",
		uruguay: "烏拉圭",
		vatican: "梵蒂岡",
		yemen: "也门",
		yugoslavia: "南斯拉夫",
		zambia: "赞比亚",
		zimbabwe: "津巴布韦",
		china: "中國",
	}
};
