export default {
	adds:{
		one: "アカウント設定",
		two: "入金設定",
		three: "アイデンティティ設定",
		four: "ユーザーフィードバック",
		five: "ログオンを終了する",
		verifySuccess: "資格認定",
		verifyFailed: "認証され",
		black: '計画書',
	},
	types: [
		{ v: 5, name: '金融勘定' },
		{ v: 4, name: '契約アカウント' },
		{ v: 3, name: '不換紙幣口座' },
		{ v: 2, name: '契約アカウント' },
		{ v: 1, name: 'コイン口座' },
	],
	billTypes: [
		{v: 101, name: 'リチャージ'},
		{v: 102, name: 'デポジット'},
		{v: 103, name: '凍結'},
		{v: 104, name: 'デフロスト'},
		{v: 105, name: 'デポジット'},
		{v: 106, name: 'デポジット'},
		{v: 201, name: '引出しの凍結'},
		{v: 202, name: '出金'},
		{v: 203, name: '出金に成功'},
		{v: 204, name: '現金の引き出しに失敗した'},
		{v: 205, name: '出金手数料'},
		{v: 206, name: 'トランスファー・アウト'},
		{v: 207, name: 'に転送します。'},
		{v:208,name:'貨幣転出'},
		{v:209,name:'貨幣転入'},
		{v:301,name:'貨幣手数料'},
		{v:302,name:'契約収益'},
		{v:303,name:'契約損失'},
		{v:311,name:'オプションの購入'},
		{v:312,name:'オプション収益'},
		{v:313,name:'オプション返品'},
		{v:314,name:'オプション手数料'},
		{v:315,name:'貨幣購入凍結'},
		{v:316,name:'貨幣購入控除'},
		{v:317,name:'貨幣購入リターン'},
		{v:318,name:'貨幣購入入金'},
		{v:319,name:'貨幣購入入金'},
		{v:320,name:'貨幣販売凍結'},
		{v:321,name:'貨幣売却控除'},
		{v:322,name:'貨幣売り戻'},
		{v:323,name:'貨幣が帳簿に売られた'},
		{v:324,name:'貨幣が帳簿に売られた'},
		{v:325,name:'貨幣手数料'},
		{v:401,name:'鉱機参入'},
		{v:402,name:'鉱機リターン'},
		{v:403,name:'鉱山収益'},
		{v:404,name:'鉱機退出'},
		{v:405,name:'鉱機退出手数料'},
		{v:501,name:'ブラインドボックス購入'},
		{v:502,name:'ブラインドボックス収入'},
	],
	common: {
		confirm: "確認します",
		cancel: "キャンセル",
		submit: "提出します",
		reset: "リセットします",
		next: "次のステップです",
		review: "審査中です",
		copy_success: "複製成功です！",
		copy_error: "コピー失敗です！",
		more: "もっとです",
		success: "成功です",
		fail: "失敗です",
		place_input: "内容の入力をお願いします",
		form_empty: "記録はまだありません",
		download: "ダウンロードします",
		history_record: "歴史的記録です",
		closing_position: "平倉",
		tip: "ヒント",
		forget: "パスワードを忘れる",
		all: "すべて",
	},
	message: {
		cancel_order: "委任を取り消すかどうか?",
		stop_out: "平倉庫を強制するか?",
	},
	header: {
		nav1: "仏貨取引",
		nav2: "現物取引",
		nav3: "てこ取引",
		nav4: "秒契約取引",
		nav5: "貨幣を預ける宝物",
		nav6: "IEOサブスクリプション",
		nav7: "資産",
		setting_account: "アカウントの設定",
		setting_collection: "入金設定",
		setting_authentication: "アイデンティティ設定",
		setting_feedback: "ユーザフィードバック",
		logout: "ログオンの終了",
	},
	footer: {
		tit1: "私たちについて",
		tit2: "ユーザーサポート",
		tit3: "ヘルプセンター",
		tit4: "連絡先",
		tit5: "オンラインカスタマーサービス",
		p1: "プラットフォームの概要",
		p2: "プロジェクト公示",
		p3: "プライバシーポリシー",
		p4: "メールボックスの登録方法",
		p5: "実名認証方法",
		p6: "トランザクションパスワードを設定する方法",
		p7: "チャージとは何か、どのようにチャージするか",
		p8: "何が貨幣を引き出すのか、どのように貨幣を引き出すのか",
		p9: "通貨取引初心者ガイド",
	},
	assets: {
		asset_verview: "資産総覧です",
		asset_verview_conversion: "資産総覧換算です",
		account_assets: "口座資産です",
		financial_records: "財務記録です",
		tab_trade: "コインアカウントです",
		tab_legal: "法貨口座です",
		tab_lever: "契約口座です",
		tab_second: "スピード契約口座です",
		tab_financial: "理財口座です",
		tab_transfer: "回転します",
		recharge: "コインチャージ",
		withdraw: "コインを引き上げます。",
		currency_transfer: "貨幣振り替え",
		asset_transfer: "振り替え資産です",
		transfer_record: "回転記録です",
	},
	deposit: {
		tab_deposit: "DeFi",
		tab_record: "DeFiレコードです",
		reference_annualization: "参考年化です",
		state: "状態です",
		term: "期限です",
		day: "天です",
		buy: "購入します",
		min_amount: "最小金額",
		daily: "日次生産高",
		waiting_time: "待機時間",
		time: "時間",
		everyday: "毎日",
		escrow_funds: "供託資金",
		returnType: "期限切れの返品",
		early_withdrawal: "前払金",
		estimated_income: "予想収益",
		available_assets: "利用可能な資産"
	},
	home: {
		download: "West Walletダウンロードします",
		ios_download: "IOSダウンロードします",
		android_download: "アンドロイドのダウンロードです",
		banner_p1: "ポートフォリオをすばやく動かします-->",
		banner_p2: "すばやく起動",
		banner_p3: "暗号ポートフォリオです",
		banner_p4: "West Walletは暗号通貨の売買が最も簡単な場所です。すぐに登録して利用を開始します",
		view_more: "詳細を表示",
		p1: "世界有数のデジタル資産取引プラットフォームです",
		p2: "世界130カ国以上の数百万人のユーザーに、安全で信頼性のあるデジタル資産取引および資産管理サービスを提供しています。",
		p3: "安全で信頼できます",
		p4: " デジタル資産金融サービスの年間経験があります",
		p5: "専門分散型アーキテクチャとDDOS攻撃防止システムです",
		p6: "地球規模の生態学的配置です",
		p7: "50カ国以上が現地化取引サービスセンターを設立しています",
		p8: "多業態が一体となったブロックチェーンの生態系をつくる",
		p9: "ユーザー至上主義です",
		p10: "先払いの仕組みを作ることです",
		p11: "投資家保護基金を設立します",
		p12: "デジタル通貨への旅を始めます",
		p13: "最も便利なデジタル通貨の取引方法をご提供します。",
		p14: "いつでもどこでもマルチプラットフォーム端末取引が可能です",
		p15: "iOS、Android、Windowsをカバーし、フルサービスに対応しています。",
	},
	ieo: {
		tab_iep: "DeFi",
		tab_record: "DeFi記録します",
		label_issue_price: "発行価格です",
		label_number: "申込数",
		label_money: "支払いを合計する",
		label_time: "申込期間です",
	},
	legal: {
		banner_p1: "C2C:デジタルマネーの迅速な売買",
		banner_p2: "C2Cポイント・トゥ・ポイント取引のプラットフォームです，ユーザーはC2Cで直接業者と接触することができます",
		banner_p3: "ビットコインや他のデジタル通貨を希望する価格や支払いで売買します。",
		table_legal:"取引ホールです",
		table_order:"売買コイン註文です",
		buy: "購入します",
		sell: "売ります",
		steps_title: "3つのステップでP2P取引を行い、コインを買う手数料はゼロです！",
		steps_tit1: "お店を選びます。",
		steps_tit2: "売り手への入金/支払いです。",
		steps_tit3: "暗号通貨の発行/受け取りです",
		steps_p1: "お好みの価格と支払い方法の広告を選択します。。",
		steps_p2: "註文を完了するために購入/販売数量と成約金額を入力します。",
		steps_p3: "提案された支払い方法で販売者に入金/支払いをします。。",
		steps_p4: "法コインの取引を完了した後,P2P West Walletでクリックします",
		steps_p5: "[振り込みました、売り手にお知らせします]。West Walletは料金を徴収しません。。",
		steps_p6: "販売者は代金を受け取ると通貨を発行します。法幣のアカウントに行きました",
		steps_p7: "受け取った通貨です。入金確認後,West Wallet P2Pでお願いします。",
		steps_p8: "買い手に暗号通貨を発行します。",
	},
	lever: {
		market_trand: "時価取引です",
		limit_trand: "指値取引です",
		tip: "1の手は1 btcです",
		handicap: "盤口です",
		whole_transaction: "全駅成約です",
		tab1: "現在のポジション",
		tab2: "全ポジション持ちです",
		tab3: "全部請求します",
		tab4: "すべて平倉です",
		tab5: "すべて請求を取り下げます",
		tab6: "ワンタッチ平倉です",
		close_position: "平倉を確定します",
		position1: "すべて平倉です",
		position2: "複数の依頼だけです",
		position3: "ただの空想です",
		positionState1: "保留中",
		positionState2: "平倉を待つ",
		positionState3: "完了",
	},
	login: {
		tip1: "ログイン歓迎ですWest Wallet",
		tip2: "いつでもどこでも取引する",
		tip3: "West Walletへようこそ",
		tip4: "ようこそMAX OKOKへ",
	},
	second: {
		transfer_model: "取引モデルです",
		open_number: "オープン数量です。",
		open_time: "オープン時間です",
		second_account: "秒契約口座です",
		profit_rate: "利益率です",
		buy_long: "買い上がりです",
		buy_short: "買い下がりです。",
		transfering: "取引中です",
		closed_position: "平倉になりました",
	},
	trade: {
		transfer_amount: "取引額です",
		buy: "買います",
		sell: "売ります",
		now_entrust: "当面の依頼です",
		all_entrust: "全て依頼します",
		history_entrust: "歴史的依頼です",
	},
	personal: {
			tit: "基本情報",
			tit1: "個人",
			tit2: "セキュリティレベル",
			state1: "強い",
			nav1: "メールボックスの検証",
			nav2: "私の招待コード",
			nav3: "ログインパスワード",
			nav4: "トランザクションパスワード",
			nav5: "入金設定",
			nav6: "資格認定",
			nav7: "ユーザフィードバック",
			modify: "変更",
			goSetting: "設定解除",
			login_psd: "ログインパスワードの変更",
			trans_pad: "トランザクションパスワードの変更",
			collection: "入金設定",
			authentication: "資格認定",
			feedback: "ユーザフィードバック"
	},
	form: {
		label_oldPsd: "古いパスワード",
		place_oldPsd: "古いパスワードを入力してください",
		label_newPsd: "新しいパスワード",
		place_newPsd: "新しいパスワードを入力してください",
		label_crmPsd: "パスワードの確認",
		place_crmPsd: "新しいパスワードを再入力してください",
		label_bankType: "支払い方法",
		place_bankType: "入金方法を選択してください",
		label_bank: "銀行カード",
		label_realName:"実名",
		palce_realName:"実名を入力してください",
		label_bankName:"口座開設銀行",
		palce_bankName:"口座明細を入力してください",
		label_bankBranch:"口座開設支店",
		palce_bankBranch:"口座支店を入力してください",
		label_bankNumber:"銀行カード番号",
		palce_bankNumber:"銀行カード番号を入力してください",
		label_cardNumber: "証明書番号",
		place_cardNumber: "証明書番号を入力してください",
		card_img1: "証明書のトップページをアップロードするには",
		card_img2: "証明書情報のアップロードページをクリックしてください",
		card_img_tip:"送信された情報は暗号化されて隔離され、送信された情報はシステムによって自動的に監査されますので、安心して記入してください。",
		label_feedbackType: "質問タイプ",
		place_feedbackType: "質問タイプを選択してください",
		feedbackType0: "引き出し関連",
		feedbackType1: "ブロックチェーン関連",
		label_feedbackTitle: "タイトル",
		place_feedbackTitle: "タイトルを入力してください",
		label_feedbackCont: "説明",
		place_feedbackCont: "説明を入力してください",
		place_currency: "通貨を選択します。",
		place_account: "口座の選択をお願いします",
		label_acrrency: "貨幣種です",
		label_number: "数です",
		label_record: "記録します",
		label_time: "時間です",
		label_available: "使えます",
		label_freeze: "凍結します",
		label_total: "換算します",
		label_operate: "操作します",
		label_transfer_currency: "貨幣種を回す",
		label_transfer_account: "振り替え口座です",
		label_account_balance: "口座残高です",
		label_transfer_number: "回転数です",
		place_transfer_number: "回転数の入力をお願いします",
		label_buy_price: "買い入れ価格です",
		label_buy_number: "買い入れ量です",
		place_buy_market: "市場の最適価格で買います。",
		label_sell_price: "売値です",
		label_sell_number: "販売量です",
		place_sell_market: "市場最適価格で売ります。",
		product_name: "製品名です",
		buy_total: "総購入数です",
		earnings: "累積収益",
		product_cycle: "製品サイクルです",
		state: "状態です",
		time: "時間です",
		place_phone_email: "携帯メールです",
		login: "登録します",
		register: "登録します",
		label_price: "値段です",
		place_price: "価格を入力願います。",
		label_multiple: "倍数です",
		place_multiple: "倍数をお願いします",
		label_hand: "手数です",
		place_hand: "手数入力お願いします。",
		label_volume: "24H量です",
		daily_fluctuations: "日によって騰落します",
		label_quotes: "三日近くです",
		label_toTrade: "取引に行きます",
		available_amount: "利用可能額です",
		label_transfer_psd: "取引暗号です",
		palce_transfer_psd: "取引パスワードをお願いします。",
		buy_long: "買いすぎです",
		sell_short: "空売りです",
		elail_login: "メールアドレス登録です",
		phone_login: "携帯電話ログイン",
		label_email: "メールボックスです",
		place_email: "メールをお願いします。",
		label_phone: "携帯番号",
		place_phone: "携帯番号を入力してください",
		label_password: "パスワードです",
		place_password: "パスワードをお願いします",
		remember_psd: "パスワードを覚えます",
		no_account: "まだ口座番号がありません？",
		forget_psd: "パスワードを忘れました？",
		label_verify: "認証コード",
		place_Verify: "認証コードを入力してください",
		send: "発送します",
		label_invite: "招待コードです",
		place_invite: "招待コードの入力をお願いします",
		have_account: "口座番号があります？",
		go_login: "登録します",
		label_linkName: "チェーン名",
		label_recharge_address: "外貨引出先住所",
		place_recharge_address: "貨幣引出先住所を入力してください",
		label_recharge_number: "チャージ数量",
		place_recharge_number: "チャージ数量を入力してください",
		label_wallet_address: "ウォレットアドレス",
		place_wallet_address: "ウォレットアドレスを入力してください",
		label_withdraw_number: "外貨引出数量",
		place_withdraw_number: "貨幣引出数量を入力してください",
		place_min_withdraw: "最小外貨引出数量",
		label_remark: "コメント",
		place_remark: "コメントを入力してください",
		label_transPsd: "トランザクションパスワード",
		place_transPsd: "取引パスワードを入力してください",
		message1: "金額を入力してください",
		message2: "アカウント残高が不足しています",
		label_amount: "金額",
		place_amount: "金額を入力してください",
	},
	table: {
		label_time: "時間です",
		label_title: "取引です",
		label_direction: "方向です",
		label_price: "値段です",
		label_number: "数です",
		label_volume: "出来高です",
		label_operate: "操作します",
		label_a呼値ですcrrency: "貨幣種です",
		label_last_price: "最新価格です",
		label_ncrease: "上げ幅です",
		label_height_price: "最高値です",
		label_low_price: "最低価格です",
		label_quantity: "24H量です",
		label_buyPrice: "購入価格です",
		label_finalPrice: "成約価格",
		label_expectedProfit: "予想損益",
		label_countDown: "カウントダウン",
		sell: "売る",
		buy: "買う",
		label_type: "タイプです",
		label_open_price: "オープン値です",
		label_now_price: "現在価格です",
		label_bail: "保証金です",
		label_stop_profit: "しめきりか",
		label_stop_loss: "損切り価格です",
		label_open_time: "オープン時間です",
		label_fee: "手数料です",
		label_overnight_fee: "宵越し費",
		label_profit_loss: "損益です",
		label_total_amount: "合計金額",
		label_order_time: "オーダー時間",
		label_order_income: "受注収益",
		label_state: "ステータス",
		label_endTime: "終了時間",
		state0: "レビュー保留中",
		state1: "完了",
	},
	params: {
		recordType1: "支出します",
		recordType2: "チャージします",
		ieoState0: "全部です",
		ieoState1: "予約販売中です",
		ieoState2: "申込中です",
		ieoState3: "終わりました",
		depositState0: "熱い進行中です",
		depositState1: "ご期待願います",
		lockStateType0: "実行中",
		lockStateType1: "終了",
		lockStateType2: "キャンセル済み",
	},
    city: {
		albania: "アルバニア",
		algeria: "アルジェリア",
		argentina: "アルゼンチン",
		armenia: "アルメニア",
		australia: "オーストラリア",
		pakistan: "パキスタン",
		austria: "オーストリア",
		bahrain: "オーストリア",
		belgium: "ベルギー",
		bosnia_and_Herzegovina: "ボスニア・ヘルツェゴビナ",
		brazil: "ブラジル",
		brunei: "ブルネイ",
		bulgaria: "ブルガリア",
		cambodia: "カンボジア",
		canada: "カナダ",
		cameroon: "カメルーン",
		chile: "チリ",
		colombia: "コロンビア",
		costa_Rica: "コスタリカ",
		croatia: "クロアチア",
		cyprus: "キプロス",
		czech_Republic: "チェコ共和国",
		denmark: "デンマーク",
		dominican_Republic: "ドミニカ共和国",
		egypt: "エジプト",
		estonia: "エストニア",
		ethiopia: "エチオピア",
		finland: "フィンランド",
		france: "フランス",
		georgia: "グルジア",
		germany: "ドイツ",
		ghana: "ガーナ",
		greece: "ギリシャ",
		guyana: "ガイアナ",
		honduras: "ホンジュラス",
		hong_Kong_China: "中国香港",
		hungary: "ハンガリー",
		iceland: "アイスランド",
		ireland: "アイルランド",
		italy: "イタリア",
		india: "インド",
		indonesia: "インドネシア",
		israel: "イスラエル",
		iran: "イラン",
		iraq: "イラク",
		japan: "日本",
		kazakstan: "カザフスタン",
		kenya: "ケニア",
		korea: "韓国",
		kuwait: "クウェート",
		kyrgyzstan: "キルギス",
		laos: "ラオス",
		latvia: "ラトビア",
		lithuania: "リトアニア",
		luxembourg: "ルクセンブルク",
		macao_China: "中国マカオ",
		macedonia: "マケドニア",
		malaysia: "マレーシア",
		malta: "マルタ",
		mexico: "メキシコ",
		moldova: "モルドバ",
		monaco: "モナコ",
		mongolia: "モンゴル",
		montenegro: "モンテネグロ共和国",
		morocco: "モロッコ",
		myanmar: "ミャンマー",
		netherlands: "オランダ",
		new_Zealand: "ニュージーランド",
		nepal: "ネパール",
		nigeria: "ナイジェリア",
		norway: "ノルウェー",
		oman: "オマーン",
		palestine: "パレスチナ",
		panama: "パナマ",
		paraguay: "パラグアイ",
		peru: "ペルー",
		philippines: "フィリピン",
		poland: "ポーランド",
		portugal: "ポルトガル",
		puerto_Rico: "プエルトリコ",
		qatar: "カタール",
		romania: "ルーマニア",
		russia: "ロシア",
		republic_of_Trinidad_and_Tobago: "トリニダード・トバゴ共和国",
		rwanda: "ルワンダ",
		saudi_Arabia: "サウジアラビア",
		serbia: "セルビア",
		singapore: "シンガポール",
		slovakia: "スロバキア",
		slovenia: "スロベニア",
		south_Africa: "南アフリカ共和国",
		spain: "スペイン",
		sri_Lanka: "スリランカ",
		sweden: "スウェーデン",
		switzerland: "スイス",
		taiwan_China: "中国台湾",
		tajikistan: "タジキスタン",
		tanzania: "タンザニア",
		thailand: "タイ",
		turkey: "トルコ",
		turkmenistan: "トルクメニスタン",
		ukraine: "ウクライナ",
		united_Arab_Emirates: "アラブ首長国連邦",
		united_Kingdom: "英国",
		united_States: "アメリカ",
		uzbekistan: "ウズベキスタン",
		venezuela: "ベネズエラ",
		vietnam: "ベトナム",
		afghanistan: "アフガニスタン",
		angola: "アンゴラ",
		azerbaijan: "アゼルバイジャン",
		bangladesh: "バングラデシュ",
		belarus: "ベラルーシ",
		belize: "ベリーズ",
		benin: "ベニン",
		bhutan: "ブータン",
		bolivia: "ボリビア",
		botswana: "ボツワナ",
		british_Virgin_Islands: "イギリス領ヴァージン諸島",
		burkina_Faso: "ブルキナファソ",
		burundi: "ブルンジ",
		cape_Verde: "カーボベルデ角",
		cayman_Islands: "ケイマン諸島",
		central_African_Republic: "中央アフリカ共和国",
		chad: "チャド",
		comoros: "コモロ",
		the_Republic_of_the_Congo: "コンゴ（布）",
		democratic_Republic_of_the_Congo: "コンゴ（金）",
		djibouti: "ジブチ",
		ecuador: "エクアドル",
		el_Salvador: "エルサルバドル",
		equatorial_Guinea: "赤道ギニア",
		eritrea: "エリトリア",
		fiji: "フィジー",
		gabon: "ガボン",
		gambia: "ガンビア",
		greenland: "グリーンランド",
		guatemala: "グアテマラ",
		guinea: "ギニア",
		haiti: "ハイチ",
		isle_of_Man: "マーン島",
		cote_d_Ivoire: "コートジボワール",
		jamaica: "ジャマイカ",
		jordan: "ヨルダン",
		lebanon: "レバノン",
		lesotho: "レソト",
		liberia: "リベリア",
		libya: "リビア",
		madagascar: "マダガスカル",
		malawi: "マラウイ",
		maldives: "モルディブ",
		mali: "マリ",
		mauritania: "モーリタニア",
		mauritius: "モーリシャス",
		mozambique: "モザンビーク",
		namibia: "ナミビア",
		nicaragua: "ニカラグア",
		republic_of_Niger: "ニジェール",
		north_Korea: "朝鲜",
		reunion: "レユニオン",
		san_Marino: "サンマリノ",
		senegal: "セネガル",
		sierra_Leone: "シエラレオネ",
		somalia: "ソマリア",
		sudan: "スーダン",
		suriname: "スリナム",
		eswatini: "スワジランド",
		syria: "シリア",
		togo: "トーゴ",
		tonga: "トンガ",
		tunisia: "チュニジア",
		united_States_Virgin_Islands: "アメリカ領ヴァージン諸島",
		uganda: "ウガンダ",
		uruguay: "ウルグアイ",
		vatican: "バチカン",
		yemen: "イエメン",
		yugoslavia: "ユーゴスラビア",
		zambia: "ザンビア",
		zimbabwe: "ジンバブエ",
		china: "中国",
	}
};
