import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/noPermissions',
    name: 'noPermissions',
    component: () => import('@/views/noPermissions/noPermissions'),
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('@/views/home/notice'),
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('@/views/home/list'),
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('@/views/home/download'),
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('@/views/login/forgot'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login'),
  },
  {
    path: '/black',
    name: 'black',
    component: () => import('@/views/home/black'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/login/register'),
  },
  {
    path: '/legal',
    name: 'legal',
    component: () => import('@/views/legal/index'),
  },
  {
    path: '/legal/order',
    name: 'legal',
    component: () => import('@/views/legal/order'),
  },
  {
    path: '/trade',
    name: 'trade',
    component: () => import('@/views/trade/index'),
  },
  {
    path: '/lever',
    name: 'lever',
    component: () => import('@/views/lever/index'),
  },
  {
    path: '/second',
    name: 'second',
    component: () => import('@/views/second/index'),
  },
  {
    path: '/deposit',
    name: 'deposit',
    component: () => import('@/views/deposit/index'),
  },
  {
    path: '/deposit/record',
    name: 'deposit',
    component: () => import('@/views/deposit/record'),
  },
  {
    path: '/ieo',
    name: 'ieo',
    component: () => import('@/views/ieo/index'),
  },
  {
    path: '/ieo/record',
    name: 'ieo',
    component: () => import('@/views/ieo/record'),
  },
  {
    path: '/assets/trade',
    name: 'assets',
    component: () => import('@/views/assets/trade'),
  },
  {
    path: '/assets/record',
    name: 'assets',
    component: () => import('@/views/assets/record'),
  },
  {
    path: '/assets/order',
    name: 'assets',
    component: () => import('@/views/assets/order'),
  },
  {
    path: '/assets/transfer',
    name: 'assets',
    component: () => import('@/views/assets/transfer'),
  },
  {
    path: '/personal/setting',
    name: 'personal',
    component: () => import('@/views/personal/setting'),
  },
  {
    path: '/personal/loginPsd',
    name: 'personal',
    component: () => import('@/views/personal/loginPsd'),
  },
  {
    path: '/personal/transferPsd',
    name: 'personal',
    component: () => import('@/views/personal/transferPsd'),
  },
  {
    path: '/personal/collection',
    name: 'personal',
    component: () => import('@/views/personal/collection'),
  },
  {
    path: '/personal/authentication',
    name: 'personal',
    component: () => import('@/views/personal/authentication'),
  },
  {
    path: '/personal/feedback',
    name: 'personal',
    component: () => import('@/views/personal/feedback'),
  },

]
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {

  return originalPush.call(this, location).catch(err => err)

}


const router = new VueRouter({
  routes
})

export default router
