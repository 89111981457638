export default {
	adds:{
		one: "Paramètres du compte",
		two: "Paramètres de la collection",
		three: "Paramètres d'identité",
		four: "Commentaires des utilisateurs",
		five: "Déconnexion",
		verifySuccess: "Certifié par",
		verifyFailed: "Non certifié",
		black: 'Prospectus',
	},
	types: [
		{ v: 5, name: "Compte financier" },
		{ v: 4, name: "Compte des contrats extrêmes" },
		{ v: 3, name: "Compte en monnaie fiduciaire" },
		{ v: 2, name: "Compte du contrat" },
		{ v: 1, name: "Compte de pièces" },
	],
	billTypes: [
		{v: 101, name: "Recharge (argent sur une carte)"},
		{v: 102, name: "Dépôt"},
		{v: 103, name: "Le gel (prêt, salaire, prix, etc.)"},
		{v: 104, name: "Dégeler"},
		{v: 105, name: "Dépôt"},
		{v: 106, name: "Dépôt"},
		{v: 201, name: "Gel des retraits"},
		{v: 202, name: "Retirer des fonds"},
		{v: 203, name: "Retrait réussi"},
		{v: 204, name: "Ne pas retirer les fonds"},
		{v: 205, name: "Frais de retrait"},
		{v: 206, name: "Transfert"},
		{v: 207, name: "Passer à"},
		{v: 208, name: "Transfert de pièces"},
		{v: 209, name: "Transfert de pièces"},
		{v: 301, name: "Frais de traitement des pièces"},
		{v: 302, name: "Revenus contractuels"},
		{v: 303, name: "Perte de contrat"},
		{v: 311, name: "Achat d options"},
		{v: 312, name: "Gain sur option"},
		{v: 313, name: "Retours d options"},
		{v: 314, name: "Frais de traitement des options"},
		{v: 315, name: "Gel de l achat de pièces"},
		{v: 316, name: "Déduction pour l,achat de pièces de monnaie"},
		{v: 317, name: "Retour des achats de pièces"},
		{v: 318, name: "Achat de pièces de monnaie pour le compte"},
		{v: 319, name: "Achat de pièces de monnaie pour le compte"},
		{v: 320, name: "Gel des ventes de pièces de monnaie"},
		{v: 321, name: "Déduction pour vente de pièces de monnaie"},
		{v: 322, name: "Coin Sell Returns"},
		{v: 323, name: "Vente de pièces sur un compte"},
		{v: 324, name: "Vente de pièces sur un compte"},
		{v: 325, name: "Frais de traitement des pièces"},
		{v: 401, name: "Les mineurs adhèrent"},
		{v: 402, name: "Retour des mineurs"},
		{v: 403, name: "Recettes provenant des machines d'exploitation minière"},
		{v: 404, name: "Sortie de la machine minière"},
		{v: 405, name: "Taxe de sortie de l'exploitation minière"},
		{v: 501, name: "Achat d'une boîte aveugle"},
		{v: 502, name: "Recettes des boîtes aveugles"},
	],
	common: {
		confirm: "Reconnaître",
		cancel: "Annulations",
		submit: "Soumettre (un rapport, etc.)",
		reset: "Reprovision",
		next: "L'étape suivante",
		review: "En cours d'examen",
		copy_success: "Succès de copie！",
		copy_error: "Échec de la reproduction！",
		more: "Plus",
		success: "Succès",
		fail: "échouer (par exemple, expériences)",
		place_input: "Veuillez saisir le contenu",
		form_empty: "Pas d'enregistrement",
		download: "Téléchargement",
		history_record: "Historique",
		closing_position: "Fermer un poste",
		tip: "Attirer l'attention sur qqch.",
		forget: "Mot de passe oublié ?",
		all: "Complet",
	},
	message: {
		cancel_order: "Révoquer ou non la commission?",
		stop_out: "Si la position est forcée ou non d'être fermée?",
	},
	header: {
		nav1: "Commerce de monnaies fiduciaires",
		nav2: "Commerce de pièces de monnaie",
		nav3: "Négociation de contrats",
		nav4: "Deuxième contrat (dans le domaine du sport)",
		nav5: "DeFi",
		nav6: "IEO",
		nav7: "Mes atouts",
		setting_account: "Paramètres du compte",
		setting_collection: "Paramètres de la collection",
		setting_authentication: "Paramètres d'identité",
		setting_feedback: "Commentaires des utilisateurs",
		logout: "Déconnexion",
	},
	footer: {
		tit1: "À propos de nous",
		tit2: "L'assistance aux utilisateurs",
		tit3: "Centre d'aide",
		tit4: "Nous contacter",
		tit5: "Service clientèle en ligne",
		p1: "Introduction de la plate-forme",
		p2: "Annonce du projet",
		p3: "Politique de confidentialité",
		p4: "Comment s'inscrire par courriel",
		p5: "Comment authentifier votre nom",
		p6: "Comment définir un mot de passe pour la négociation",
		p7: "Qu'est-ce que le réapprovisionnement en pièces, comment réapprovisionner les pièces ?",
		p8: "Qu'est-ce que le retrait de pièces, comment retirer des pièces ?",
		p9: "Guide du débutant en matière de commerce des pièces de monnaie",
	},
	assets: {
		asset_verview: "Aperçu des actifs",
		asset_verview_conversion: "Aperçu des actifs convertis",
		account_assets: "Actifs du compte",
		financial_records: "Registres financiers",
		tab_trade: "Compte de pièces",
		tab_legal: "Compte en monnaie fiduciaire",
		tab_lever: "Compte du contrat",
		tab_second: "Compte des contrats extrêmes",
		tab_financial: "Compte financier",
		tab_transfer: "Transfert",
		recharge: "Chargement des pièces",
		withdraw: "Retirer de l'argent",
		currency_transfer: "Transfert de devises",
		asset_transfer: "Transfert d'actifs",
		transfer_record: "Transfert de dossiers",
	},
	deposit: {
		tab_deposit: "DeFi",
		tab_record: "Fiches DeFi",
		reference_annualization: "Référence Annualisée",
		state: "état des lieux",
		term: "Délais",
		day: "Jour",
		buy: "L'achat",
		min_amount: "Montant minimum",
		daily: "Production quotidienne",
		waiting_time: "Temps d'attente",
		time: "Fois",
		everyday: "Tous les jours",
		escrow_funds: "Fonds détenus en fiducie",
		returnType: "Rendement à l'échéance",
		early_withdrawal: "Retrait anticipé d'espèces",
		estimated_income: "Revenu prévu",
		available_assets: "Actif disponible"
	},
	home: {
		download: "West Wallet Télécharger",
		ios_download: "Téléchargement IOS",
		android_download: "Téléchargement Android",
		banner_p1: "Allumez rapidement votre portefeuille -->",
		banner_p2: "Démarrez rapidement votre",
		banner_p3: "portefeuille crypté",
		banner_p4: "West Wallet est l'endroit le plus facile pour acheter et vendre des crypto-monnaies. Inscrivez-vous maintenant et commencez !",
		view_more: "Voir plus",
		p1: "La première plateforme d'échange d'actifs numériques au monde",
		p2: "Fournir des services sécurisés et fiables d'échange et de gestion d'actifs numériques à des millions d'utilisateurs dans plus de 130 pays à travers le monde.",
		p3: "Sûr et digne de confiance",
		p4: " Des années d'expérience dans les services financiers d'actifs numériques.",
		p5: "Architecture professionnelle distribuée et système d'attaque anti-DDOS",
		p6: "Aménagement écologique global",
		p7: "Centres de services commerciaux localisés dans plus de 50 pays",
		p8: "Créer un écosystème blockchain avec de multiples formes d'entreprises dans son ensemble",
		p9: "L'utilisateur est au premier plan",
		p10: "Mise en place d'un mécanisme de paiement anticipé",
		p11: "Création d'un fonds de protection des investisseurs",
		p12: "Commencez votre voyage en monnaie numérique dès aujourd'hui !",
		p13: "Vous offrir le moyen le plus pratique d'échanger des devises numériques",
		p14: "Terminal multiplateforme permettant d'effectuer des transactions à tout moment et en tout lieu",
		p15: "Couvrir plusieurs plates-formes iOS, Android et Windows, en prenant en charge les fonctions de service complet.",
	},
	ieo: {
		tab_iep: "DeFi",
		tab_record: "Fiches DeFi",
		label_issue_price: "Prix d'émission",
		label_number: "Abonnement",
		label_money: "Total des paiements",
		label_time: "Période d'abonnement",
	},
	legal: {
		banner_p1: "C2C : Acheter et vendre rapidement des monnaies numériques",
		banner_p2: "C2C est une plateforme d'échange peer-to-peer, où les utilisateurs peuvent interagir directement avec les commerçants.",
		banner_p3: "Achetez et vendez des bitcoins et d'autres monnaies numériques au prix et selon le mode de paiement souhaités.",
		table_legal: "Salle des marchés",
		table_order: "Ordres d'achat et de vente de pièces",
		buy: "L'achat",
		sell: "Offre de vente",
		steps_title: "Trois étapes pour effectuer une transaction P2P et acheter des pièces sans frais !",
		steps_tit1: "Sélectionnez votre commerçant",
		steps_tit2: "Recevoir/payer les vendeurs",
		steps_tit3: "Émission/réception de crypto-monnaie",
		steps_p1: "Choisissez une annonce qui correspond au prix et au mode de paiement que vous souhaitez.",
		steps_p2: "Saisissez la quantité achetée/vendue et le montant de la transaction pour terminer l'ordre.",
		steps_p3: "Recevoir/payer le vendeur via le mode de paiement proposé.",
		steps_p4: "Après avoir effectué la transaction en monnaie fiduciaire, sur West Wallet P2P, cliquez sur le bouton",
		steps_p5: "[Transféré, informer le vendeur]. West Wallet ne facturera pas de frais.",
		steps_p6: "La monnaie sera libérée lorsque le vendeur recevra le paiement. Aller au compte fiat pour voir",
		steps_p7: "Vous recevez la monnaie. Après avoir confirmé la réception de l'argent, vous pourrez le voir dans le système P2P de West Wallet.",
		steps_p8: "Sur l'émission de crypto-monnaies aux acheteurs。",
	},
	lever: {
		market_trand: "Transaction au prix du marché",
		limit_trand: "Limiter les échanges",
		tip: "1 lot équivaut à 1BTC",
		handicap: "Carrefour",
		whole_transaction: "Transaction sur l'ensemble du site",
		tab1: "Position actuelle",
		tab2: "Position entière",
		tab3: "Toutes les commandes en cours",
		tab4: "Clôturer une position entière",
		tab5: "Retrait de toutes les commandes",
		tab6: "Fermer la position en un seul clic",
		close_position: "Détermination de la fermeture de la position",
		position1: "Clôturer une position entière",
		position2: "Commandes multiples uniquement",
		position3: "s'appuyer uniquement sur des ordres vides (idiome) ; sans aucune preuve",
		positionState1: "Dans l'attente de l'ouverture du poste",
		positionState2: "Dans l'attente de la clôture d'un poste",
		positionState3: "Fait",
	},
	login: {
		tip1: "Bienvenue à West Wallet",
		tip2: "Négocier à tout moment et en tout lieu",
		tip3: "Bienvenue à West Wallet",
		tip4: "Bienvenue à West Wallet",
	},
	second: {
		transfer_model: "Modèle de négociation",
		open_number: "Nombre de postes ouverts",
		open_time: "Heure d'ouverture",
		second_account: "Compte de contrats de seconde main",
		profit_rate: "Rentabilité",
		buy_long: "Acheter (prix)",
		buy_short: "Acheter et vendre à la baisse (prix)",
		transfering: "En cours de négociation",
		closed_position: "Position fermée",
	},
	trade: {
		transfer_amount: "Rotation",
		buy: "Participation",
		sell: "Atteindre (un prix dans une vente aux enchères)",
		now_entrust: "Commissions actuelles",
		all_entrust: "Commission entière",
		history_entrust: "Commissions historiques",
	},
	personal: {
			tit: "Informations de base",
			tit1: "Personnes",
			tit2: "Niveau de sécurité",
			state1: "Vigoureux",
			nav1: "Vérification du courrier électronique",
			nav2: "Mon code d'invitation",
			nav3: "Mot de passe de connexion",
			nav4: "Mot de passe de négociation",
			nav5: "Paramètres de la collection",
			nav6: "Authentifier",
			nav7: "Commentaires des utilisateurs",
			modify: "Modifications",
			goSetting: "Aller de l'avant et mettre en place",
			login_psd: "Modifier le mot de passe de connexion",
			trans_pad: "Modifier le mot de passe de trading",
			collection: "Paramètres de la collection",
			authentication: "Authentifier",
			feedback: "Commentaires des utilisateurs"
	},
	form: {
		label_oldPsd: "Ancien mot de passe",
		place_oldPsd: "Veuillez saisir l'ancien mot de passe",
		label_newPsd: "Nouveau mot de passe",
		place_newPsd: "Veuillez saisir un nouveau mot de passe",
		label_crmPsd: "Confirmer le mot de passe",
		place_crmPsd: "Veuillez saisir à nouveau le nouveau mot de passe",
		label_bankType: "Mode de paiement",
		place_bankType: "Veuillez sélectionner un mode de paiement",
		label_bank: "Carte de guichet",
		label_realName:"Nom et prénom",
		palce_realName:"Veuillez saisir votre nom réel",
		label_bankName:"Ouverture de compte banque",
		palce_bankName:"Veuillez saisir le compte bancaire",
		label_bankBranch:"Ouverture de compte Succursale",
		palce_bankBranch:"Veuillez indiquer l'agence où le compte est ouvert",
		label_bankNumber:"Numéro de la carte ATM",
		palce_bankNumber:"Veuillez saisir votre numéro de carte bancaire",
		label_cardNumber: "Numéro de certificat",
		place_cardNumber: "Veuillez saisir votre numéro d'identification",
		card_img1: "Cliquez pour télécharger la page d'accueil de l'ID",
		card_img2: "Cliquez pour télécharger la page d'information sur les documents",
		card_img_tip:"Les informations que vous soumettez seront cryptées et stockées de manière isolée, et les informations soumises seront automatiquement examinées par le système, alors n'hésitez pas à les compléter.",
		label_feedbackType: "Type de problème",
		place_feedbackType: "Veuillez sélectionner le type de question",
		feedbackType0: "Liées au retrait",
		feedbackType1: "Liés à la blockchain",
		label_feedbackTitle: "Légende",
		place_feedbackTitle: "Veuillez saisir un titre",
		label_feedbackCont: "Descriptions",
		place_feedbackCont: "Veuillez saisir une description",
		place_currency: "Veuillez sélectionner la devise",
		place_account: "Veuillez sélectionner un compte",
		label_acrrency: "Type de monnaie",
		label_number: "Quantités",
		label_record: "Record (en sport, etc.)",
		label_time: "Fois",
		label_available: "L'utilisabilité",
		label_freeze: "Le gel (prêt, salaire, prix, etc.)",
		label_total: "S'élève à",
		label_operate: "Gréement",
		label_transfer_currency: "Monnaie de transfert",
		label_transfer_account: "Compte de transfert",
		label_account_balance: "Solde du compte",
		label_transfer_number: "Nombre de transferts",
		place_transfer_number: "Veuillez indiquer le nombre de transferts",
		label_buy_price: "Prix de l'offre",
		label_buy_number: "Volume d'achat",
		place_buy_market: "Acheter au meilleur prix du marché",
		label_sell_price: "Taux de vente",
		label_sell_number: "Volume des ventes",
		place_sell_market: "Vendre au meilleur prix du marché",
		product_name: "Chlorure de diéthylammonium",
		buy_total: "Nombre total d'achats",
		earnings: "Gain cumulé",
		product_cycle: "Cycle du produit",
		state: "État",
		time: "Heure",
		place_phone_email: "Téléphone/Email",
		login: "Se connecter",
		register: "S'inscrire",
		label_price: "Prix",
		place_price: "Veuillez saisir le prix",
		label_multiple: "Multiplicateur",
		place_multiple: "Veuillez sélectionner un multiplicateur",
		label_hand: "Lots",
		place_hand: "Veuillez saisir le nombre de lots",
		label_volume: "Volume sur 24h",
		daily_fluctuations: "Fluctuations quotidiennes",
		label_quotes: "Citations des trois derniers jours",
		label_toTrade: "Accéder à la négociation",
		available_amount: "Montant disponible",
		label_transfer_psd: "Mot de passe de transfert",
		place_transfer_psd: "Veuillez entrer le mot de passe de transfert",
		buy_long: "Acheter à long terme",
		sell_short: "Vendre à découvert",
		elail_login: "Connexion par e-mail",
		phone_login: "Connexion par téléphone",
		label_email: "E-mail",
		place_email: "Veuillez entrer votre adresse e-mail",
		label_phone: "Numéro de téléphone",
		place_phone: "Veuillez entrer votre numéro de téléphone",
		label_password: "Mot de passe",
		place_password: "Veuillez entrer votre mot de passe",
		remember_psd: "Se souvenir du mot de passe",
		no_account: "Pas encore de compte ?",
		forget_psd: "Mot de passe oublié ?",
		label_verify: "Code de vérification",
		place_Verify: "Veuillez entrer le code de vérification",
		send: "Envoyer",
		label_invite: "Code d'invitation",
		place_invite: "Veuillez entrer le code d'invitation",
		have_account: "Vous avez déjà un compte ?",
		go_login: "Se connecter",
		label_linkName: "Nom du lien",
		label_recharge_address: "Adresse de dépôt",
		place_recharge_address: "Veuillez entrer l'adresse de dépôt",
		label_recharge_number: "Montant de dépôt",
		place_recharge_number: "Veuillez entrer le montant de dépôt",
		label_wallet_address: "Adresse de portefeuille",
		place_wallet_address: "Veuillez entrer l'adresse de portefeuille",
		label_withdraw_number: "Montant de retrait",
		place_withdraw_number: "Veuillez entrer",
		place_min_withdraw: "Montant minimum de retrait",
		label_remark: "Remarque",
		place_remark: "Veuillez entrer une remarque",
		label_transPsd: "Mot de passe de transaction",
		place_transPsd: "Veuillez entrer le mot de passe de transaction",
		message1: "Veuillez entrer le montant",
		message2: "Solde insuffisant sur le compte",
		label_amount: "Montant",
		place_amount: "Veuillez entrer le montant",
	},
	table: {
		label_time: "Heure",
		label_title: "Paire de trading",
		label_direction: "Direction",
		label_price: "Prix",
		label_number: "Quantité",
		label_volume: "Volume des transactions",
		label_operate: "Opération",
		label_acrrency: "Devise",
		label_last_price: "Dernier prix",
		label_ncrease: "Variation",
		label_height_price: "Prix le plus élevé",
		label_low_price: "Prix le plus bas",
		label_quantity: "Volume sur 24 heures",
		label_buyPrice: "Prix d'achat",
		label_finalPrice: "Prix final",
		label_expectedProfit: "Profit attendu",
		label_countDown: "Compte à rebours",
		sell: "Vendre",
		buy: "Acheter",
		label_type: "Type",
		label_open_price: "Prix d'ouverture",
		label_now_price: "Prix actuel",
		label_bail: "Marge",
		label_stop_profit: "Prix de prise de bénéfices",
		label_stop_loss: "Prix de stop-loss",
		label_open_time: "Heure d'ouverture",
		label_fee: "Frais",
		label_overnight_fee: "Frais de nuit",
		label_profit_loss: "Profit/Perte",
		label_total_amount: "Montant total",
		label_order_time: "Durée de la commande",
		label_order_income: "Revenu de la commande",
		label_state: "État",
		label_endTime: "Heure de fin",
		state0: "En attente de vérification",
		state1: "Terminé",
		},
		params: {
		recordType1: "Dépense",
		recordType2: "Dépôt",
		ieoState0: "Tous",
		ieoState1: "Prévente en cours",
		ieoState2: "Souscription en cours",
		ieoState3: "Terminé",
		depositState0: "En cours",
		depositState1: "À venir",
		lockStateType0: "En cours",
		lockStateType1: "Terminé",
		lockStateType2: "Annulé",
	},
	city: {
		albania: "Albanie",
		algeria: "Algérie",
		argentina: "Argentine",
		armenia: "Arménie",
		australia: "Australie",
		pakistan: "Pakistan",
		austria: "Autriche",
		bahrain: "Bahreïn",
		belgium: "Belgique",
		bosnia_and_Herzegovina: "Bosnie _ Herzégovine",
		brazil: "Brésil",
		brunei: "Brunei",
		bulgaria: "Bulgarie",
		cambodia: "Cambodge",
		canada: "Au Canada",
		cameroon: "Cameroun",
		chile: "Chili",
		colombia: "Colombie",
		costa_Rica: "Costa Rica",
		croatia: "Croatie",
		cyprus: "Chypre",
		czech_Republic: "République tchèque",
		denmark: "Danemark",
		dominican_Republic: "République dominicaine",
		egypt: "Égypte",
		estonia: "Estonie",
		ethiopia: "Éthiopie",
		finland: "Finlande",
		france: "France",
		georgia: "Géorgie",
		germany: "Allemagne",
		ghana: "Ghana",
		greece: "Grèce",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, Chine",
		hungary: "Hongrie",
		iceland: "Islande",
		ireland: "Irlande",
		italy: "Italie",
		india: "Inde",
		indonesia: "Indonésie",
		israel: "Israël",
		iran: "Iran",
		iraq: "Irak",
		japan: "Japon",
		kazakstan: "Kazakhstan",
		kenya: "Kenya",
		korea: "Corée du Sud",
		kuwait: "Koweït",
		kyrgyzstan: "Kirghizistan",
		laos: "Laos",
		latvia: "Lettonie",
		lithuania: "Lituanie",
		luxembourg: "Luxembourg",
		macao_China: "Macao, Chine",
		macedonia: "Macédoine",
		malaysia: "Malaisie",
		malta: "Malte",
		mexico: "Mexique",
		moldova: "Moldavie",
		monaco: "Monaco",
		mongolia: "Mongolie",
		montenegro: "République du Monténégro",
		morocco: "Maroc",
		myanmar: "Myanmar",
		netherlands: "Pays _ Ba",
		new_Zealand: "Nouvelle _ Zélande",
		nepal: "Népal",
		nigeria: "Nigéria",
		norway: "Norvège",
		oman: "Oman",
		palestine: "Palestine",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Pérou",
		philippines: "Philippines",
		poland: "Pologne",
		portugal: "Portugal",
		puerto_Rico: "Porto Rico",
		qatar: "Qatar",
		romania: "Roumanie",
		russia: "Russie",
		republic_of_Trinidad_and_Tobago: "République de Trinité _ et _ Tobago",
		rwanda: "Rwanda",
		saudi_Arabia: "Arabie saoudite",
		serbia: "Serbie",
		singapore: "Singapour",
		slovakia: "Slovaquie",
		slovenia: "Slovénie",
		south_Africa: "Afrique du Sud",
		spain: "Espagne",
		sri_Lanka: "Sri Lanka",
		sweden: "Suède",
		switzerland: "Suisse",
		taiwan_China: "Taïwan, Chine",
		tajikistan: "Tadjikistan",
		tanzania: "Tanzanie",
		thailand: "Thaïlande",
		turkey: "Turquie",
		turkmenistan: "Turkménistan",
		ukraine: "Ukraine",
		united_Arab_Emirates: "Émirats arabes unis",
		united_Kingdom: "Royaume _ Uni",
		united_States: "États _ Unis",
		uzbekistan: "Ouzbékistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Azerbaïdjan",
		bangladesh: "Bangladesh",
		belarus: "Biélorussie",
		belize: "Belize",
		benin: "Bénin",
		bhutan: "Bhoutan",
		bolivia: "Bolivie",
		botswana: "Botswana",
		british_Virgin_Islands: "Îles Vierges britanniques",
		burkina_Faso: "Burkina Faso",
		burundi: "Burundi",
		cape_Verde: "Cap _ Vert",
		cayman_Islands: "Îles Caïmans",
		central_African_Republic: "République centrafricaine",
		chad: "Tchad",
		comoros: "Comores",
		the_Republic_of_the_Congo: "Congo (Bu)",
		democratic_Republic_of_the_Congo: "Congo (or)",
		djibouti: "Djibouti",
		ecuador: "Équateur",
		el_Salvador: "Salvador",
		equatorial_Guinea: "Guinée équatoriale",
		eritrea: "Érythrée",
		fiji: "Fidji",
		gabon: "Gabon",
		gambia: "Gambie",
		greenland: "Groenland",
		guatemala: "Guatemala",
		guinea: "Guinée",
		haiti: "Haïti",
		isle_of_Man: "Île de Man",
		cote_d_Ivoire: "Côte d'Ivoire",
		jamaica: "Jamaïque",
		jordan: "Jordanie",
		lebanon: "Liban",
		lesotho: "Lesotho",
		liberia: "Libéria",
		libya: "Libye",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldives",
		mali: "Mali",
		mauritania: "Mauritania",
		mauritius: "Maurice",
		mozambique: "Mozambique",
		namibia: "Namibie",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Corée du Nord",
		reunion: "La réunion",
		san_Marino: "Saint _ Marin",
		senegal: "Sénégal",
		sierra_Leone: "Sierra Leone",
		somalia: "Somalie",
		sudan: "Soudan",
		suriname: "Suriname",
		eswatini: "Swaziland",
		syria: "Syrie",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunisie",
		united_States_Virgin_Islands: "Îles Vierges américaines",
		uganda: "Ouganda",
		uruguay: "Uruguay",
		vatican: "Le Vatican",
		yemen: "Yémen",
		yugoslavia: "Yougoslavie",
		zambia: "Zambie",
		zimbabwe: "Zimbabwe",
		china: "Chine",
	}
};
