export default {
	adds:{
		one: "账户设置",
		two: "收款设置",
		three: "身份设置",
		four: "用户反馈",
		five: "退出登录",
		verifySuccess: "認證通過",
		verifyFailed: "未認證",
		black: '计划书',
	},
	types: [
		{ v: 5, name: '理财账户' },
		{ v: 4, name: '极速合约账户' },
		{ v: 3, name: '法币账户' },
		{ v: 2, name: '合约账户' },
		{ v: 1, name: '币币账户' },
	],
	billTypes: [
		{v: 101, name: '充值'},
		{v: 102, name: '存入'},
		{v: 103, name: '冻结'},
		{v: 104, name: '解冻'},
		{v: 105, name: '存入'},
		{v: 106, name: '存入'},
		{v: 201, name: '提现冻结'},
		{v: 202, name: '提现'},
		{v: 203, name: '提现成功'},
		{v: 204, name: '提现失败'},
		{v: 205, name: '提现手续费'},
		{v: 206, name: '转出'},
		{v: 207, name: '转入'},
		{v: 208, name: '币币转出'},
		{v: 209, name: '币币转入'},
		{v: 301, name: '币币手续费'},
		{v: 302, name: '合约收益'},
		{v: 303, name: '合约亏损'},
		{v: 311, name: '购买期权'},
		{v: 312, name: '期权收益'},
		{v: 313, name: '期权退回'},
		{v: 314, name: '期权手续费'},
		{v: 315, name: '币币买入冻结'},
		{v: 316, name: '币币买入扣除'},
		{v: 317, name: '币币买入返回'},
		{v: 318, name: '币币买入到账'},
		{v: 319, name: '币币买入到账'},
		{v: 320, name: '币币卖出冻结'},
		{v: 321, name: '币币卖出扣除'},
		{v: 322, name: '币币卖出返回'},
		{v: 323, name: '币币卖出到账'},
		{v: 324, name: '币币卖出到账'},
		{v: 325, name: '币币手续费'},
		{v: 401, name: '矿机加入'},
		{v: 402, name: '矿机返回'},
		{v: 403, name: '矿机收入'},
		{v: 404, name: '矿机退出'},
		{v: 405, name: '矿机退出手续费'},
		{v: 501, name: '盲盒购买'},
		{v: 502, name: '盲盒收入'},
	],
	common: {
		confirm: "确认",
		cancel: "取消",
		submit: "提交",
		reset: "重置",
		next: "下一步",
		review: "审核中",
		copy_success: "复制成功！",
		copy_error: "复制失败！",
		more: "更多",
		success: "成功",
		fail: "失败",
		place_input: "请输入内容",
		form_empty: "暂无记录",
		download: "下载",
		history_record: "历史记录",
		closing_position: "平仓",
		tip: "提示",
		forget: "忘记密码",
		all: "全部",
	},
	message: {
		cancel_order: "是否撤销委托?",
		stop_out: "是否强制平仓?",
	},
	header: {
		nav1: "法币交易",
		nav2: "币币交易",
		nav3: "合约交易",
		nav4: "秒合约",
		nav5: "DeFi",
		nav6: "IEO",
		nav7: "我的资产",
		setting_account: "账户设置",
		setting_collection: "收款设置",
		setting_authentication: "身份设置",
		setting_feedback: "用户反馈",
		logout: "退出登录",
	},
	footer: {
		tit1: "关于我们",
		tit2: "用户支持",
		tit3: "帮助中心",
		tit4: "联系我们",
		tit5: "在线客服",
		p1: "平台简介",
		p2: "项目公示",
		p3: "隐私政策",
		p4: "如何邮箱注册",
		p5: "如何实名认证",
		p6: "如何设置交易密码",
		p7: "什么是充币,如何充币",
		p8: "什么是提币,如何提币",
		p9: "币币交易 新手指南",
	},
	assets: {
		asset_verview: "资产总览",
		asset_verview_conversion: "资产总览折合",
		account_assets: "账户资产",
		financial_records: "财务记录",
		tab_trade: "币币账户",
		tab_legal: "法币账户",
		tab_lever: "合约账户",
		tab_second: "极速合约账户",
		tab_financial: "理财账户",
		tab_transfer: "划转",
		recharge: "充币",
		withdraw: "提币",
		currency_transfer: "币种划转",
		asset_transfer: "资产划转",
		transfer_record: "划转记录",
	},
	deposit: {
		tab_deposit: "DeFi",
		tab_record: "DeFi记录",
		reference_annualization: "参考年化",
		state: "状态",
		term: "期限",
		day: "天",
		buy: "购买",
		min_amount: "最小金额",
		daily: "日产量",
		waiting_time: "等待时间",
		time: "时间",
		everyday: "每天",
		escrow_funds: "托管资金",
		returnType: "到期返回",
		early_withdrawal: "提前提款",
		estimated_income: "预计收入",
		available_assets: "可用资产"
	},
	home: {
		download: "West Wallet下载",
		ios_download: "IOS下载",
		android_download: "安卓下载",
		banner_p1: "快速开动您的投资组合-->",
		banner_p2: "快速开动您的",
		banner_p3: "加密投资组合",
		banner_p4: "West Wallet是买卖加密货币最简单的地方。立即注册并开始使用",
		view_more: "查看更多",
		p1: "全球领先的数字资产交易平台",
		p2: "为全球超过130个国家的数百万用户提供安全、可信赖的数字资产交易及资产管理服务",
		p3: "安全可信赖",
		p4: " 年数字资产金融服务经验",
		p5: "专业分布式架构和防 DDOS 攻击系统",
		p6: "全球生态布局",
		p7: "50 多个国家设立本土化交易服务中心",
		p8: "打造多业务形态为一体的区块链生态圈",
		p9: "用户至上",
		p10: "建立先行赔付机制",
		p11: "设立投资者保护基金",
		p12: "立即开启您的数字货币之旅",
		p13: "为您提供最便捷的数字货币交易方式",
		p14: "随时随地多平台终端交易",
		p15: "覆盖iOS、Android、Windows多个平台，支持全业务功能",
	},
	ieo: {
		tab_iep: "DeFi",
		tab_record: "DeFi记录",
		label_issue_price: "发行价格",
		label_number: "认购数量",
		label_money: "合计支付",
		label_time: "认购时间",
	},
	legal: {
		banner_p1: "C2C:快捷买卖数字货币",
		banner_p2: "C2C是一个点对点交易平台，用户可以在C2C直接与商家",
		banner_p3: "用想要的价格和付款方式买卖比特币和其他数字货币。",
		table_legal: "交易大厅",
		table_order: "买卖币订单",
		buy: "购买",
		sell: "出售",
		steps_title: "三个步骤完成P2P交易，零手续费买币！",
		steps_tit1: "选择您的商户",
		steps_tit2: "向卖家收款/付款",
		steps_tit3: "发放/领取加密货币",
		steps_p1: "选择符合您喜好的价格以及支付方式的广告。",
		steps_p2: "输入购买/出售数量和成交金额以完成订单。",
		steps_p3: "通过建议的支付方式向卖家收款/付款。",
		steps_p4: "完成法币交易后，在West Wallet P2P上点击",
		steps_p5: "[已转账，通知卖家]。West Wallet 将不收取任何费用。",
		steps_p6: "卖家收到款项后将发放货币。前往法币账号查看",
		steps_p7: "您收到的货币。确认收款后，在West Wallet P2P",
		steps_p8: "上向买家发放加密货币。",
	},
	lever: {
		market_trand: "市价交易",
		limit_trand: "限价交易",
		tip: "1手等于1BTC",
		handicap: "盘口",
		whole_transaction: "全站成交",
		tab1: "当前持仓",
		tab2: "全部持仓",
		tab3: "全部挂单",
		tab4: "全部平仓",
		tab5: "全部撤单",
		tab6: "一键平仓",
		close_position: "确定平仓",
		position1: "全部平仓",
		position2: "只凭多单",
		position3: "只凭空单",
		positionState1: "待开仓",
		positionState2: "待平仓",
		positionState3: "已完成",
	},
	login: {
		tip1: "欢迎登录West Wallet",
		tip2: "随时随地交易",
		tip3: "欢迎注册West Wallet",
		tip4: "欢迎来到West Wallet",
	},
	second: {
		transfer_model: "交易模式",
		open_number: "开仓数量",
		open_time: "开仓时间",
		second_account: "秒合约账户",
		profit_rate: "盈利率",
		buy_long: "买涨",
		buy_short: "买跌",
		transfering: "交易中",
		closed_position: "已平仓",
	},
	trade: {
		transfer_amount: "交易额",
		buy: "买入",
		sell: "卖出",
		now_entrust: "当前委托",
		all_entrust: "全部委托",
		history_entrust: "历史委托",
	},
	personal: {
			tit: "基本信息",
			tit1: "个人",
			tit2: "安全等级",
			state1: "强",
			nav1: "邮箱验证",
			nav2: "我的邀请码",
			nav3: "登录密码",
			nav4: "交易密码",
			nav5: "收款设置",
			nav6: "身份认证",
			nav7: "用户反馈",
			modify: "修改",
			goSetting: "去设置",
			login_psd: "修改登录密码",
			trans_pad: "修改交易密码",
			collection: "收款设置",
			authentication: "身份认证",
			feedback: "用户反馈"
	},
	form: {
		label_oldPsd: "旧密码",
		place_oldPsd: "请输入旧密码",
		label_newPsd: "新密码",
		place_newPsd: "请输入新密码",
		label_crmPsd: "确认密码",
		place_crmPsd: "请再次输入新密码",
		label_bankType: "支付方式",
		place_bankType: "请选择收款方式",
		label_bank: "银行卡",
		label_realName:"真实姓名",
		palce_realName:"请输入真实姓名",
		label_bankName:"开户行",
		palce_bankName:"请输入开户行",
		label_bankBranch:"开户支行",
		palce_bankBranch:"请输入开户支行",
		label_bankNumber:"银行卡号",
		palce_bankNumber:"请输入银行卡号",
		label_cardNumber: "证件号",
		place_cardNumber: "请输入证件号码",
		card_img1: "点击上传证件首页",
		card_img2: "点击上传证件信息页",
		card_img_tip:"您提交的信息会进行加密隔离存储，提交的信息由系统自动审核，请放心填写。",
		label_feedbackType: "问题类型",
		place_feedbackType: "请选择问题类型",
		feedbackType0: "提款相关",
		feedbackType1: "区块链相关",
		label_feedbackTitle: "标题",
		place_feedbackTitle: "请输入标题",
		label_feedbackCont: "描述",
		place_feedbackCont: "请输入描述",
		place_currency: "请选择币种",
		place_account: "请选择账户",
		label_acrrency: "币种",
		label_number: "数量",
		label_record: "记录",
		label_time: "时间",
		label_available: "可用",
		label_freeze: "冻结",
		label_total: "折合",
		label_operate: "操作",
		label_transfer_currency: "划转币种",
		label_transfer_account: "划转账户",
		label_account_balance: "账户余额",
		label_transfer_number: "划转数量",
		place_transfer_number: "请输入划转数量",
		label_buy_price: "买入价",
		label_buy_number: "买入量",
		place_buy_market: "以市场最优价格买入",
		label_sell_price: "卖出价",
		label_sell_number: "卖出量",
		place_sell_market: "以市场最优价格卖出",
		product_name: "产品名称",
		buy_total: "购买总数量",
		earnings: "累计收益",
		product_cycle: "产品周期",
		state: "状态",
		time: "时间",
		place_phone_email: "手机邮箱",
		login: "登录",
		register: "注册",
		label_price: "价格",
		place_price: "请输入价格",
		label_multiple: "倍数",
		place_multiple: "请选择倍数",
		label_hand: "手数",
		place_hand: "请输入手数",
		label_volume: "24H量",
		daily_fluctuations: "日涨跌",
		label_quotes: "近三天",
		label_toTrade: "去交易",
		available_amount: "可用额",
		label_transfer_psd: "交易密码",
		palce_transfer_psd: "请输入交易密码",
		buy_long: "买多",
		sell_short: "卖空",
		elail_login: "邮箱登录",
		phone_login: "手机登录",
		label_email: "邮箱",
		place_email: "请输入邮箱",
		label_phone: "手机号",
		place_phone: "请输入手机号",
		label_password: "密码",
		place_password: "请输入密码",
		remember_psd: "记住密码",
		no_account: "还没有账号？",
		forget_psd: "忘记密码？",
		label_verify: "验证码",
		place_Verify: "请输入验证码",
		send: "发送",
		label_invite: "邀请码",
		place_invite: "请输入邀请码",
		have_account: "有账号？",
		go_login: "去登录",
		label_linkName: "链名称",
		label_recharge_address: "提币地址",
		place_recharge_address: "请输入提币地址",
		label_recharge_number: "充币数量",
		place_recharge_number: "请输入充币数量",
		label_wallet_address: "钱包地址",
		place_wallet_address: "请输入钱包地址",
		label_withdraw_number: "提币数量",
		place_withdraw_number: "请输入提币数量",
		place_min_withdraw: "最小提币数量",
		label_remark: "备注",
		place_remark: "请输入备注",
		label_transPsd: "交易密码",
		place_transPsd: "请输入交易密码",
		message1: "请输入金额",
		message2: "账户余额不足",
		label_amount: "金额",
		place_amount: "请输入金额",
	},
	table: {
		label_time: "时间",
		label_title: "交易对",
		label_direction: "方向",
		label_price: "价格",
		label_number: "数量",
		label_volume: "成交量",
		label_operate: "操作",
		label_acrrency: "币种",
		label_last_price: "最新价",
		label_ncrease: "涨幅",
		label_height_price: "最高价",
		label_low_price: "最低价",
		label_quantity: "24H量",
		label_buyPrice: "购买价",
		label_finalPrice: "成交价",
		label_expectedProfit: "预计盈亏",
		label_countDown: "倒计时",
		sell: "卖",
		buy: "买",
		label_type: "类型",
		label_open_price: "开仓价",
		label_now_price: "当前价",
		label_bail: "保证金",
		label_stop_profit: "止盈价",
		label_stop_loss: "止损价",
		label_open_time: "开仓时间",
		label_fee: "手续费",
		label_overnight_fee: "隔夜费",
		label_profit_loss: "盈亏",
		label_total_amount: "总金额",
		label_order_time: "订单时长",
		label_order_income: "订单收入",
		label_state: "状态",
		label_endTime: "结束时间",
		state0: "待审核",
		state1: "已完成",
	},
	params: {
		recordType1: "支出",
		recordType2: "充值",
		ieoState0: "全部",
		ieoState1: "预售中",
		ieoState2: "认购中",
		ieoState3: "已结束",
		depositState0: "火热进行中",
		depositState1: "敬请期待",
		lockStateType0: "运行中",
		lockStateType1: "已结束",
		lockStateType2: "已取消",
	},
	city: {
        albania: "阿尔巴尼亚",
        algeria: "阿尔及利亚",
	    argentina: "阿根廷",
	    armenia:  "亚美尼亚",
	    australia: "澳大利亚",
	    pakistan: "巴基斯坦",
        austria:  "奥地利",
	    bahrain: "巴林",
	    belgium: "比利时",
	    bosnia_and_Herzegovina: "波黑",
	    brazil: "巴西",
	    brunei: "文莱",
	    bulgaria: "保加利亚",
	    cambodia: "柬埔寨",
		canada: "加拿大",
		cameroon: "喀麦隆",
		chile: "智利",
		colombia: "哥伦比亚",
		costa_Rica: "哥斯达黎加",
		croatia: "克罗地亚",
		cyprus: "塞浦路斯",
		czech_Republic: "捷克",
		denmark: "丹麦",
		dominican_Republic: "多米尼亚共和国",
		egypt: "埃及",
		estonia: "爱沙尼亚",
		ethiopia: "埃塞俄比亚",
        finland : "芬兰",
		france: "法国",
		georgia: "格鲁吉亚",
		germany: "德国",
		ghana: "加纳",
		greece: "希腊",
		guyana: "圭亚那",
		honduras: "洪都拉斯",
		hong_Kong_China: "中国香港",
		hungary: "匈牙利",
		iceland: "冰岛",
		ireland: "爱尔兰",
		italy: "意大利",
		india: "印度",
		indonesia: "印度尼西亚",
		israel: "以色列",
		iran: "伊朗",
		iraq: "伊拉克",
		japan: "日本",
		kazakstan: "哈萨克斯坦",
		kenya: "肯尼亚",
		korea: "韩国",
		kuwait: "科威特",
		kyrgyzstan:"吉尔吉斯斯坦",
		laos:"老挝",
		latvia:"拉脱维亚",
		lithuania:"立陶宛",
		luxembourg:"卢森堡",
		macao_China:"中国澳门",
		macedonia:"马其顿",
		malaysia:"马来西亚",
		malta:"马耳他",
		mexico:"墨西哥",
		moldova:"摩尔多瓦",
		monaco:"摩纳哥",
		mongolia:"蒙古",
		montenegro:"黑山",
		morocco:"摩洛哥",
		myanmar:"缅甸",
		netherlands:"荷兰",
		new_Zealand:"新西兰",
		nepal:"尼泊尔",
		nigeria:"尼日利亚",
		norway:"挪威",
		oman:"阿曼",
		palestine:"巴勒斯坦",
		panama:"巴拿马",
		paraguay:"巴拉圭",
		peru:"秘鲁",
		philippines:"菲律宾",
		poland:"波兰",
		portugal:"葡萄牙",
		puerto_Rico:"波多黎各",
		qatar:"卡塔尔",
		romania:"罗马尼亚",
		russia:"俄罗斯",
		republic_of_Trinidad_and_Tobago:"特立尼达和多巴哥",
		rwanda:"卢旺达",
		saudi_Arabia:"沙特阿拉伯",
		serbia:"塞尔维亚",
		singapore:"新加坡",
		slovakia:"斯洛伐克",
		slovenia:"斯洛文尼亚",
		south_Africa:"南非",
		spain:"西班牙",
		sri_Lanka:"斯里兰卡",
		sweden:"瑞典",
		switzerland:"瑞士",
		taiwan_China:"中国台湾",
		tajikistan:"塔吉克斯坦",
		tanzania:"坦桑尼亚",
		thailand:"泰国",
		turkey:"土耳其",
		turkmenistan:"土库曼斯坦",
		ukraine:"乌克兰",
		united_Arab_Emirates:"阿联酋",
		united_Kingdom:"英国",
		united_States:"美国",
		uzbekistan:"乌兹别克斯坦",
		venezuela:"委内瑞拉",
		vietnam:"越南",
		afghanistan:"阿富汗",
		angola:"安哥拉",
		azerbaijan:"阿塞拜疆",
		bangladesh:"孟加拉国",
		belarus:"白俄罗斯",
		belize:"伯利兹",
		benin:"贝宁",
		bhutan:"不丹",
		bolivia:"玻利维亚",
		botswana:"博茨瓦纳",
		british_Virgin_Islands:"英属维京群岛",
		burkina_Faso:"布基纳法索",
		burundi:"布隆迪",
		cape_Verde:"佛得角",
		cayman_Islands:"开曼群岛",
		central_African_Republic:"中非共和国",
		chad:"乍得",
		comoros:"科摩罗",
		the_Republic_of_the_Congo:"刚果（布）",
		democratic_Republic_of_the_Congo:"刚果（金）",
		djibouti:"吉布提",
		ecuador:"厄瓜多尔",
		el_Salvador:"萨尔瓦多",
		equatorial_Guinea:"赤道几内亚",
		eritrea:"厄立特里亚",
		fiji:"斐济",
		gabon:"加蓬",
		gambia:"冈比亚",
		greenland:"格陵兰",
		guatemala:"危地马拉",
		guinea:"几内亚",
		haiti:"海地",
		isle_of_Man:"马恩岛",
		cote_d_Ivoire:"科特迪瓦",
		jamaica:"牙买加",
		jordan:"约旦",
		lebanon:"黎巴嫩",
		lesotho:"莱索托",
		liberia:"利比里亚",
		libya:"利比亚",
		madagascar:"马达加斯加",
		malawi:"马拉维",
		maldives:"马尔代夫",
		mali:"马里",
		mauritania:"毛里塔尼亚",
		mauritius:"毛里求斯",
		mozambique:"莫桑比克",
		namibia:"纳米比亚",
		nicaragua:"尼加拉瓜",
		republic_of_Niger:"尼日尔",
		north_Korea:"朝鲜",
		reunion:"留尼汪",
		san_Marino:"圣马力诺",
		senegal:"塞内加尔",
		sierra_Leone:"塞拉利昂",
		somalia:"索马里",
		sudan:"苏丹",
		suriname:"苏里南",
		eswatini:"斯威士兰",
		syria:"叙利亚",
		togo:"多哥",
		tonga:"汤加",
		tunisia:"突尼斯",
		united_States_Virgin_Islands:"美属维尔京群岛",
		uganda:"乌干达",
		uruguay:"乌拉圭",
		vatican:"梵蒂冈",
		yemen:"也门",
		yugoslavia:"南斯拉夫",
		zambia:"赞比亚",
		zimbabwe:"津巴布韦",
		china:"中国",
    }
};
